import { Edit, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { MONTHS, getYears } from "helpers/constants";
import React, { useCallback, useEffect } from "react";

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const projectExpenseData = queryResult?.data?.data;

  const { selectProps: projectSelectProps } = useSelect({
    resource: "projects",
    defaultValue: projectExpenseData?.projectId,
    optionLabel: "name",
  });

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  const calculateAndSetAmount = useCallback(() => {
    const rate = formProps.form?.getFieldValue("rate");
    const qty = formProps.form?.getFieldValue("qty");
    formProps.form?.setFieldValue("amount", (rate ?? 0) * (qty ?? 0));
  }, [formProps.form]);

  useEffect(() => {
    calculateAndSetAmount();
  }, [calculateAndSetAmount]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Id"
              name={["id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly disabled />
            </Form.Item>
            <Form.Item
              label="Project"
              name={"projectId"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...projectSelectProps} />
            </Form.Item>
            <Form.Item
              label="Description"
              name={["description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Rate"
              name={["rate"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber onChange={() => calculateAndSetAmount()} />
            </Form.Item>
            <Form.Item
              label="Quantity"
              name={["qty"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber onChange={() => calculateAndSetAmount()} />
            </Form.Item>
            <Form.Item
              label="Amount"
              name={["amount"]}
              rules={[
              ]}
            >
              <Input prefix="LKR" disabled />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={10}>
            <Form.Item
              label="Year"
              name={["year"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={years} />
            </Form.Item>
            <Form.Item
              label="Month"
              name={["month"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={months}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

import { Edit, SaveButtonProps, useForm, useSelect } from "@refinedev/antd";
import { BaseKey, IResourceComponentsProps } from "@refinedev/core";
import {
  Card,
  Col,
  Collapse,
  Form,
  FormProps,
  Row,
  SelectProps,
  Space,
} from "antd";
import React from "react";

// Components
import { CompanyDataFormItems } from "./components/EditPage/CompanyDataFormItems";
import { CreatedAtAndUpdatedAtFormItems } from "./components/EditPage/CreatedAtAndUpdatedAtFormItems";
import { EmployeeFormItems } from "./components/EditPage/EmployeeFormItems";
import { MetadataFormItems } from "./components/EditPage/MetadataFormItems";
import { NotesFormItems } from "./components/EditPage/NotesFormItems";
import { PayslipItemsList } from "./components/payslipItems/PayslipItemsList";
import PayslipButton from "components/PayslipButton";

const { Panel } = Collapse;

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const payslipsData = queryResult?.data?.data;

  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    defaultValue: payslipsData?.employeeId,
  });

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card>
        <EditForm
          saveButtonProps={saveButtonProps}
          formProps={formProps}
          employeeSelectProps={employeeSelectProps}
        />
      </Card>
      <Card>
        <PayslipItemsList payslipId={payslipsData?.id as BaseKey} />
      </Card>
    </Space>
  );
};
const EditForm: React.FC<{
  saveButtonProps: SaveButtonProps;
  formProps: FormProps;
  employeeSelectProps: SelectProps;
}> = ({ saveButtonProps, formProps, employeeSelectProps }) => {
  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <PayslipButton
            recordItemId={formProps.form?.getFieldValue("id")}
            label={"Printable Payslip"}
          />
          {defaultButtons}
        </>
      )}
    >
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Transaction Dates" key="1">
                <MetadataFormItems />
              </Panel>
              <Panel header="Company" key="2">
                <CompanyDataFormItems />
              </Panel>
              <Panel header="Employee" key="3">
                <EmployeeFormItems employeeSelectProps={employeeSelectProps} />
              </Panel>
              <Panel header="Notes" key="4">
                <NotesFormItems />
              </Panel>
              <Panel header="Metadata" key="5">
                <CreatedAtAndUpdatedAtFormItems />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

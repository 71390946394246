import axios, { AxiosError, AxiosResponse } from "axios";
import { getUserTokenFromLocalStorage } from "helpers/utils";

const axiosInstance: any = axios.create();

axiosInstance.interceptors.response.use(
  ({ data, status }: AxiosResponse) => {
    return { data, status };
  },
  (error: AxiosError) => {
    console.log("error-log @axiosHelper.ts", error);

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject({
      error: "Unhandled Error",
      message: error.message,
      status: error.code,
      statusCode: error.code,
    });
  }
);

axiosInstance.interceptors.request.use(
  async (config: { headers: { Authorization: string } }) => {
    config.headers = {
      Authorization: `Bearer ${getUserTokenFromLocalStorage()}`,
    };
    return config;
  }
);

export default axiosInstance;

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";

export const NewTaskForm = () => <>

  <Form.List name={"tasks"}>
    {(fields, { add, remove }) => (<>
      {fields.map(({ key, name, ...restField }) => (
        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
          <Form.Item
            {...restField}
            name={[name, "summary"]}
            rules={[{ required: true, message: 'Missing summary' }]}
          >
            <Input placeholder="Summary" />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "description"]}
            rules={[{ required: false }]}
          >
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "hours"]}
            rules={[{ required: true, message: 'Missing hours' }]}
          >
            <Input type="number" placeholder="hours" />
          </Form.Item>
          <MinusCircleOutlined onClick={() => remove(name)} />
        </Space>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
          Add Task
        </Button>
      </Form.Item>
    </>)}
  </Form.List>
</>;

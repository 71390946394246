import { useSelect } from "@refinedev/antd";
import { Form, Select } from "antd";
import React from "react";
import { MONTHS, getYears } from "../../../helpers/constants";
import { IProject } from "../../projects/types";

const MetadataFormItems: React.FC = () => {

  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    optionLabel: "user.username",
  });

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  return <>
    <Form.Item
      label="Employee"
      name={["worklog", "projectEngagement", "employeeId"]}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select
        options={employeeSelectProps.options?.map(({ label, value }) => ({
          label: label,
          value: value,
        }))}
      />
    </Form.Item>
    <Form.Item
      label="Project"
      name={["worklog", "projectEngagement", "projectId"]}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select {...projectSelectProps} />
    </Form.Item>
    <Form.Item
      label="Year"
      name={["worklog", "year"]}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select options={years} />
    </Form.Item>
    <Form.Item
      label="Month"
      name={["worklog", "month"]}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select options={months}/>
    </Form.Item>
  </>
}

export default MetadataFormItems;
import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { Col, Divider, Row, Typography } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { CL_PREFIX, LKR_CURRENCY } from "helpers/constants";
import React from "react";
import { IClient } from "./types";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: clientData, isLoading: clientIsLoading } = useOne<IClient>({
    resource: "clients",
    id: record?.clientId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Row>
        <Col span={10}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Project Code</Title>
          <TextField value={record?.projectCode} />
          <Title level={5}>Name</Title>
          <TextField value={record?.name} />
          <Title level={5}>Description</Title>
          <TextField value={record?.description} />
          <Title level={5}>Client</Title>
          {clientIsLoading ? (
            <>...</>
          ) : (
            <ResourceNavButton
              buttonType="link"
              resource="clients"
              page="show"
              resourceId={record?.clientId}
              label={`${CL_PREFIX} ${clientData?.data.clientCode}: 
                              ${clientData?.data.user?.firstName} ${clientData?.data.user?.lastName}
                    `}
              style={{ paddingLeft: "0px" }}
            />
          )}
        </Col>
        <Col span={2}>
          <Divider type="vertical" />
        </Col>
        <Col span={12}>
          <Title level={5}>Billing Hourly Rate</Title>
          <TextField
            value={`${LKR_CURRENCY} ${record?.billingHourlyRate ?? "<?>"}`}
          />
          <Title level={5}>Target Dev Hours</Title>
          <NumberField value={record?.targetDevHours ?? ""} />
          <Title level={5}>Project Type</Title>
          <TextField value={record?.projectType} />
          <Title level={5}>Duration Months</Title>
          <NumberField value={record?.durationMonths ?? ""} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12}>
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
        </Col>
        <Col span={12}>
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
    </Show>
  );
};

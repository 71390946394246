// App constants
export const TOKEN_KEY = "invoyze-access-token";
export const USER_KEY = "invoyze-user";
const DEV_API_URL = "http://localhost:3002/api";
const PROD_API_URL = "https://seahorse-app-d2opu.ondigitalocean.app/api";

export const API_URL = PROD_API_URL;

export const RANDOM_AVATAR_LINK =
  "https://xsgames.co/randomusers/avatar.php?g=pixel";

// Functional constants
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const STATUS = ["Pending", "Approved", "Rejected"];

export const TITLES = [
  { id: "SE(Intern)", name: "Software Engineer (Intern)" },
  { id: "HR(Intern)", name: "HR Executive (Intern)" },
  { id: "PM(Intern)", name: "Project Manager (Intern)" },
  { id: "ASE", name: "Associate Software Engineer" },
  { id: "SE", name: "Software Engineer" },
  { id: "SSE", name: "Senior Software Engineer" },
  { id: "ATL", name: "Associate Tech Lead" },
  { id: "TL", name: "Tech Lead" },
  { id: "STL", name: "Senior Tech Lead" },
  { id: "PM", name: "Project Manager" },
  { id: "HRM", name: "HR Manager" },
  { id: "SHRM", name: "Senior HR Manager" },
  { id: "FM", name: "Finance Manager" },
  { id: "SFM", name: "Senior Finance Manager" },
];

export const EMP_PREFIX = "EMP";
export const CL_PREFIX = "CL";
export const LKR_CURRENCY = "LKR";

export enum EProjectType {
  Milestone = "Milestone",
  Monthly = "Monthly",
}

export const PROJECT_TYPES = [EProjectType.Milestone, EProjectType.Monthly];

export const getYears = (count = 5) => {
  const currentYear = new Date().getFullYear();
  let y = [];
  for (let i = currentYear - count; i <= currentYear + count; i++) {
    y.push(i);
  }
  return y;
};

import {
  Col, DatePicker,
  Form, Row
} from "antd";
import dayjs from "dayjs";
import React from "react";

export const CreatedAtAndUpdatedAtFormItems = () => (
  <Row>
    <Col span={10}>
      <Form.Item
        label="Created At"
        name={["createdAt"]}
        rules={[]}
        getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined,
        })}
      >
        <DatePicker disabled />
      </Form.Item>
    </Col>
    <Col span={4}></Col>
    <Col span={10}>
      <Form.Item
        label="Updated At"
        name={["updatedAt"]}
        rules={[]}
        getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined,
        })}
      >
        <DatePicker disabled />
      </Form.Item>
    </Col>
  </Row>
);

import { AuthPage } from "@refinedev/antd";

export const Login = () => {
  return (
    <AuthPage
      type="login"
      title="Invoyze V3 Admin"
      formProps={{
        initialValues: { email: "admin@mail.com", password: "Abc@1234" },
      }}
    />
  );
};

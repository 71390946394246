import { InfoCircleOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Button, Row, Typography } from "antd";
import ResourceNavButton from "components/ResourceNavButton";

const { Text } = Typography;

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <Row>
        <Text mark>
          <span>
            <InfoCircleOutlined />
            {
              " Worklogs cannot be created manually. They are created automatically when you log a new task."
            }
          </span>
        </Text>
      </Row>
      <Row>
        <ResourceNavButton
          resource="worklogs"
          type="list"
          label="Go to Worklogs"
          buttonType="primary"
        />
      </Row>
    </>
  );
};

import {
  Col, DatePicker,
  Form,
  Input,
  Row
} from "antd";
import dayjs from "dayjs";
import React from "react";

export const MetadataFormItems = () => (
  <Row>
    <Col span={8}>
      <Form.Item
        label="Id"
        name={["id"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label="Month"
        name={["month"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Year"
        name={["year"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={2}></Col>
    <Col span={14}>
      <Form.Item
        label="Salary Date"
        name={["salaryDate"]}
        rules={[
          {
            required: true,
          },
        ]}
        getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined,
        })}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Transaction Date"
        name={["transactionDate"]}
        rules={[
          {
            required: true,
          },
        ]}
        getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined,
        })}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Send To Email"
        name={["sendToEmail"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
  </Row>
);

import { Edit, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Form, Input, Row, Select } from "antd";
import { PROJECT_TYPES } from "helpers/constants";
import React from "react";
import { IProject } from "./types";

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IProject>();

  const projectsData = queryResult?.data?.data;

  const { selectProps: clientSelectProps } = useSelect({
    resource: "clients",
    defaultValue: projectsData?.client?.clientCode,
    optionLabel: "user.username",
    optionValue: "id",
  });
  
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Id"
              name={["id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly disabled />
            </Form.Item>
            <Form.Item
              label="Project Code"
              name={["projectCode"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name={["name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name={["description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Client"
              name={["clientId"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...clientSelectProps} />
            </Form.Item>
          </Col>
          <Col span={10} offset={2}>
            <Form.Item
              label="Billing Hourly Rate"
              name={["billingHourlyRate"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Target Dev Hours"
              name={["targetDevHours"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Project Type"
              name={["projectType"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={PROJECT_TYPES.map((type) => ({
                  label: type,
                  value: type,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Duration Months"
              name={["durationMonths"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

import { FileSyncOutlined } from "@ant-design/icons";
import { BaseKey, useDataProvider, useNotification } from "@refinedev/core";
import { Button } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";
import { TPayslipRecord } from "./types";
import { constuctPayslipData, timeout } from "./utils";

interface Props {
  hideText?: boolean;
  size?: SizeType;
  recordItemId?: BaseKey;
  label?: string;
}

const PayslipButton: React.FC<Props> = ({
  hideText,
  size,
  recordItemId,
  label = "Payslip",
}) => {
  const getDataProvider = useDataProvider();
  const dataProvider = getDataProvider();
  const { open: notify } = useNotification();

  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async (e: any) => {
    setLoading(true);
    await timeout(1000);
    // Get the data from the record
    dataProvider
      .getOne({
        resource: "payslips",
        id: recordItemId || "",
      })
      .then((response) => {
        setLoading(false);

        e.preventDefault();
        e.stopPropagation();

        // Go to invoyze.com and populate the data
        window.open(
          `https://invoyze.com/?invoice=${btoa(
            JSON.stringify(constuctPayslipData(response.data as TPayslipRecord))
          )}`,
          "_blank"
        );
      })
      .catch((error) => {
        console.error("error", error);
        notify?.({
          message: "Payslip",
          description: "Payslip is not loading to view",
          type: "error",
        });
      });
  };

  return (
    <Button
      size={size || "middle"}
      icon={<FileSyncOutlined />}
      loading={loading}
      disabled={!recordItemId}
      onClick={handleOnClick}
    >
      {hideText ? "" : label}
    </Button>
  );
};

export default PayslipButton;

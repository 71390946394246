import {
  Col, Divider,
  Form,
  Input,
  Row
} from "antd";
import React from "react";

export const NotesFormItems = () => (
  <Row>
    <Divider>Notes</Divider>
    <Col span={24}>
      <Form.Item
        label="Notes 1 Title"
        name={["notes_1Title"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Notes 1 Description"
        name={["notes_1Description"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Notes 2 Title"
        name={["notes_2Title"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Notes 2 Description"
        name={["notes_2Description"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
  </Row>
);

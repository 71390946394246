import { Authenticated, Refine, ResourceProps } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { EllipsisOutlined } from "@ant-design/icons";
import {
  ErrorComponent,
  ThemedLayoutV2,
  notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { AntdInferencer } from "@refinedev/inferencer/antd";
import nestjsxCrudDataProvider from "@refinedev/nestjsx-crud";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// Layout and general components
import { Title } from "components/layout";
import { Header } from "./components/layout/header";

// Auth Pages
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";

// Resource Pages
import CompanyPage from "pages/company";

// Other
import axiosInstance from "helpers/axiosHelper";
import { API_URL } from "helpers/constants";
import { resourcesWithComponents } from "resourcesWithComponents";
import { authProvider } from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";

// Extend ResourceProps with JSX.Element
export type ResourcePropsWithComponents = ResourceProps & {
  listComponent?: JSX.Element;
  createComponent?: JSX.Element;
  editComponent?: JSX.Element;
  showComponent?: JSX.Element;
};

// TODO: use this to group menu headers
const menuHeaders: ResourceProps[] = [
  {
    name: "master",
    meta: {
      icon: <EllipsisOutlined />,
    },
  },
  {
    name: "clients",
    meta: {
      icon: <EllipsisOutlined />,
    },
  },
  {
    name: "projects",
    meta: {
      icon: <EllipsisOutlined />,
    },
  },
  {
    name: "employees",
    meta: {
      icon: <EllipsisOutlined />,
    },
  },
];

function App() {
  const dataProvider = nestjsxCrudDataProvider(API_URL, axiosInstance as any);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            resources={[...resourcesWithComponents]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="manage-clients" />}
                />
                {/* Iterate through resources and create Routes */}
                {resourcesWithComponents.map(
                  (
                    {
                      list,
                      listComponent,
                      createComponent,
                      editComponent,
                      showComponent,
                    },
                    index
                  ) => (
                    <Route path={list as string} key={index}>
                      <Route
                        index
                        element={
                          listComponent ? listComponent : <AntdInferencer />
                        }
                      />
                      <Route
                        path="create"
                        element={
                          createComponent ? createComponent : <AntdInferencer />
                        }
                      />
                      <Route
                        path="edit/:id"
                        element={
                          editComponent ? editComponent : <AntdInferencer />
                        }
                      />
                      <Route
                        path="show/:id"
                        element={
                          showComponent ? showComponent : <AntdInferencer />
                        }
                      />
                    </Route>
                  )
                )}
                <Route path="/company-settings" element={<CompanyPage />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;

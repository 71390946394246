import { Button } from "antd";
import React from "react";

type SteppedFormFooterButtonSetProps = {
  formList: JSX.Element[];
  gotoStep: (step: number) => void;
  current: number;
  lastButton: JSX.Element;
}

export const SteppedFormFooterButtonSet: React.FC<SteppedFormFooterButtonSetProps> = ({
  formList,
  gotoStep,
  current,
  lastButton
}) => (
  <>
    {current > 0 && (
      <Button
        onClick={() => {
          gotoStep(current - 1);
        }}
      >
        Previous
      </Button>
    )}
    {current < formList.length - 1 && (
      <Button
        onClick={() => {
          gotoStep(current + 1);
        }}
      >
        Next
      </Button>
    )}
    {current === formList.length - 1 && lastButton}
  </>
);

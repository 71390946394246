import {
  BarChartOutlined,
  CheckSquareOutlined,
  DollarOutlined,
  FileZipOutlined,
  FundProjectionScreenOutlined,
  LinkOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ResourcePropsWithComponents } from "App";
import clientsPage from "pages/clients";
import employeesPage from "pages/employees";
import payslipsPage from "pages/payslips";
import projectEngagementPage from "pages/project-engagements";
import projectExpensesPage from "pages/project-expenses";
import projectIncomePage from "pages/project-income";
import projectsPage from "pages/projects";
import tasksPage from "pages/tasks";
import worklogsPage from "pages/worklogs";

export const resourcesWithComponents: ResourcePropsWithComponents[] = [
  {
    identifier: "manage-clients",
    name: "clients",
    list: "/clients",
    create: "/clients/create",
    edit: "/clients/edit/:id",
    show: "/clients/show/:id",
    meta: {
      icon: <SmileOutlined />,
    },
    listComponent: <clientsPage.listPage />,
    createComponent: <clientsPage.createPage />,
    editComponent: <clientsPage.editPage />,
    showComponent: <clientsPage.showPage />,
  },
  {
    identifier: "manage-projects",
    name: "projects",
    list: "/projects",
    create: "/projects/create",
    edit: "/projects/edit/:id",
    show: "/projects/show/:id",
    meta: {
      icon: <FundProjectionScreenOutlined />,
    },
    listComponent: <projectsPage.listPage />,
    createComponent: <projectsPage.createPage />,
    editComponent: <projectsPage.editPage />,
    showComponent: <projectsPage.showPage />,
  },
  {
    identifier: "manage-project-income",
    name: "project-income",
    list: "/project-income",
    create: "/project-income/create",
    edit: "/project-income/edit/:id",
    show: "/project-income/show/:id",
    meta: {
      icon: <FileZipOutlined />,
    },
    listComponent: <projectIncomePage.listPage />,
    createComponent: <projectIncomePage.createPage />,
    editComponent: <projectIncomePage.editPage />,
    showComponent: <projectIncomePage.showPage />,
  },
  {
    identifier: "manage-project-expenses",
    name: "project-expenses",
    list: "/project-expenses",
    create: "/project-expenses/create",
    edit: "/project-expenses/edit/:id",
    show: "/project-expenses/show/:id",
    meta: {
      icon: <FileZipOutlined />,
    },
    listComponent: <projectExpensesPage.listPage />,
    createComponent: <projectExpensesPage.createPage />,
    editComponent: <projectExpensesPage.editPage />,
    showComponent: <projectExpensesPage.showPage />,
  },
  {
    identifier: "manage-employees",
    name: "employees",
    list: "/employees",
    create: "/employees/create",
    edit: "/employees/edit/:id",
    show: "/employees/show/:id",
    meta: {
      icon: <UserOutlined />,
    },
    listComponent: <employeesPage.listPage />,
    createComponent: <employeesPage.createPage />,
    editComponent: <employeesPage.editPage />,
    showComponent: <employeesPage.showPage />,
  },
  {
    identifier: "manage-project-engagements",
    name: "project-engagements",
    list: "/project-engagements",
    create: "/project-engagements/create",
    edit: "/project-engagements/edit/:id",
    show: "/project-engagements/show/:id",
    meta: {
      canDelete: true,
      icon: <LinkOutlined />,
    },
    listComponent: <projectEngagementPage.listPage />,
    createComponent: <projectEngagementPage.createPage />,
    editComponent: <projectEngagementPage.editPage />,
    showComponent: <projectEngagementPage.showPage />,
  },
  {
    identifier: "manage-tasks",
    name: "tasks",
    list: "/tasks",
    create: "/tasks/create",
    edit: "/tasks/edit/:id",
    show: "/tasks/show/:id",
    meta: {
      canDelete: true,
      icon: <CheckSquareOutlined />,
    },
    listComponent: <tasksPage.listPage />,
    createComponent: <tasksPage.createPage />,
    editComponent: <tasksPage.editPage />,
    showComponent: <tasksPage.showPage />,
  },
  {
    identifier: "manage-worklogs",
    name: "worklogs",
    list: "/worklogs",
    create: "/worklogs/create",
    edit: "/worklogs/edit/:id",
    show: "/worklogs/show/:id",
    meta: {
      canDelete: true,
      icon: <BarChartOutlined />,
    },
    listComponent: <worklogsPage.listPage />,
    createComponent: <worklogsPage.createPage />,
    editComponent: <worklogsPage.editPage />,
    showComponent: <worklogsPage.showPage />,
  },
  {
    identifier: "manage-payslips",
    name: "payslips",
    list: "/payslips",
    create: "/payslips/create",
    edit: "/payslips/edit/:id",
    show: "/payslips/show/:id",
    meta: {
      canDelete: true,
      icon: <DollarOutlined />,
    },
    listComponent: <payslipsPage.listPage />,
    createComponent: <payslipsPage.createPage />,
    editComponent: <payslipsPage.editPage />,
    showComponent: <payslipsPage.showPage />,
  },
  // {
  //   name: "template-resources",
  //   list: "/template-resources",
  //   create: "/template-resources/create",    // Note: Pass this to customize route for create page path
  //   edit: "/template-resources/edit/:id",
  //   show: "/template-resources/show/:id",
  //   meta: {
  //     canDelete: true,
  //     // parent: "master",
  //     icon: <ShrinkOutlined />,
  //   },
  //   listComponent: <templateResource.listPage />,
  //   createComponent: <templateResource.createPage />,
  //   editComponent: <templateResource.editPage />,
  //   showComponent: <templateResource.showPage />,
  // },
];

import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { Col, Row, Typography } from "antd";
import { LKR_CURRENCY } from "helpers/constants";
import React from "react";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: employeeData, isLoading: employeeIsLoading } = useOne({
    resource: "employees",
    id: record?.employeeId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: techLeadData, isLoading: techLeadLoading } = useOne({
    resource: "employees",
    id: record?.techLeadId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: projectData, isLoading: projectIsLoading } = useOne({
    resource: "projects",
    id: record?.projectId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading} canDelete={false}>
      <Row>
        <Col span={12}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Employee</Title>
          {employeeIsLoading ? (
            <>Loading...</>
          ) : (
            <>{`${employeeData?.data?.user.firstName} ${employeeData?.data?.user.lastName}`}</>
          )}
          <Title level={5}>Role Title</Title>
          <TextField value={record?.roleTitle} />
          <Title level={5}>Project</Title>
          {projectIsLoading ? <>Loading...</> : <>{projectData?.data?.name}</>}
          <Title level={5}>Hourly Rate</Title>
          <TextField value={LKR_CURRENCY + " " + record?.hourlyRate ?? ""} />
          <Title level={5}>Tech Lead</Title>
          {employeeIsLoading ? (
            <>Loading...</>
          ) : (
            <>{`${techLeadData?.data?.user.firstName} ${techLeadData?.data?.user.lastName}`}</>
          )}
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
    </Show>
  );
};

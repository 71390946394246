import { InfoCircleOutlined } from "@ant-design/icons";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import { MONTHS, STATUS, getYears } from "helpers/constants";
import { IProject } from "./../projects/types";

const { Text } = Typography;

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    optionLabel: "user.username",
  });

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  const statuses: { value: string; label: string }[] = STATUS.map(
    (statusName) => ({
      label: statusName,
      value: statusName,
    })
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Text mark>
          <span>
            <InfoCircleOutlined />
            {" You can only update the status of the worklog"}
          </span>
        </Text>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Id"
              name={["id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={10}>
            <Form.Item
              label="Status"
              name={["status"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={statuses} />
            </Form.Item>
            <Form.Item
              label="Status Changed By"
              name={["statusChangedById"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={employeeSelectProps.options?.map(
                  ({ label, value }) => ({
                    label: label,
                    value: value,
                  })
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Form.Item
              label="Employee"
              name={["projectEngagement", "employeeId"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                disabled
                options={employeeSelectProps.options?.map(
                  ({ label, value }) => ({
                    label: label,
                    value: value,
                  })
                )}
              />
            </Form.Item>
            <Form.Item
              label="Project"
              name={["projectEngagement", "projectId"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select disabled {...projectSelectProps} />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={10}>
            <Form.Item
              label="Year"
              name={["year"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select disabled options={years} />
            </Form.Item>
            <Form.Item
              label="Month"
              name={["month"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select disabled options={months} />
            </Form.Item>

            <Form.Item
              label="Worked Hours"
              name={["workedHours"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

import { Create, SaveButton, useStepsForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Form, Steps } from "antd";
import React from "react";
import { NewTaskForm } from "./components/AddNewTasksForm";
import MetadataFormItems from "./components/MetadataFormItems";
import { SteppedFormFooterButtonSet } from "./components/SteppedFormFooterButtonSet";
import { TCreateMultipleTasksRequest } from "./types";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const {
    current,
    formProps,
    gotoStep,
    stepsProps,
    saveButtonProps,
  } = useStepsForm<TCreateMultipleTasksRequest>();

  const formList = [
    <MetadataFormItems />,
    <NewTaskForm />
  ]

  return (
    <Create footerButtons={
      <SteppedFormFooterButtonSet
        formList={formList}
        current={current}
        gotoStep={gotoStep}
        lastButton={<SaveButton {...saveButtonProps} />}
      />}
    >
      <Steps {...stepsProps}
        items={[{ title: "Enter Metadata" }, { title: "Add tasks" }]}
      />
      <Form {...formProps} layout="vertical">
        {formList[current]}
      </Form>
    </Create>
  );
};

import {
  Col, Divider,
  Form,
  Input,
  Row,
  Select,
  SelectProps
} from "antd";
import React from "react";

export const EmployeeFormItems = ({
  employeeSelectProps,
}: {
  employeeSelectProps: SelectProps;
}) => (
  <Row>
    <Divider>Employee Data</Divider>
    <Col span={8}>
      <Form.Item
        label="Employee"
        name={"employeeId"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select {...employeeSelectProps} />
      </Form.Item>
      <Form.Item
        label="Employee Name"
        name={["employeeName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Employee Country"
        name={["employeeCountry"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={2}></Col>
    <Col span={14}>
      <Form.Item
        label="Employee Bank Acc No"
        name={["employeeBankAccNo"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Employee Bank Name"
        name={["employeeBankName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Employee Bank Acc Branch"
        name={["employeeBankAccBranch"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
  </Row>
);

import { CreatePage } from "./create";
import { EditPage } from "./edit";
import { ListPage } from "./list";
import { ShowPage } from "./show";

export default {
  createPage: CreatePage,
  editPage: EditPage,
  listPage: ListPage,
  showPage: ShowPage,
};

import { InfoCircleOutlined } from "@ant-design/icons";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Divider, Form, Input, InputNumber, Select, Typography } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { MONTHS, getYears } from "helpers/constants";
import { IProject } from "./../projects/types";

const { Text } = Typography;

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    optionLabel: "user.username",
  });

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Divider />
        <Text mark>
          <span>
            <InfoCircleOutlined />
            {
              " You cannot change project, employee, year and month. Please remove and"
            }
            <ResourceNavButton
              buttonType="link"
              resource="tasks"
              page="create"
              label="create a new task"
            />
            {"with the correct information"}
          </span>
        </Text>
        <Form.Item
          label="Employee"
          name={["worklog", "projectEngagement", "employeeId"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled
            options={employeeSelectProps.options?.map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Project"
          name={["worklog", "projectEngagement", "projectId"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select disabled {...projectSelectProps} />
        </Form.Item>

        <Form.Item
          label="Year"
          name={["worklog", "year"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select disabled options={years} />
        </Form.Item>
        <Form.Item
          label="Month"
          name={["worklog", "month"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select disabled options={months} />
        </Form.Item>
        <Divider />
        <Form.Item
          label="Summary"
          name={["summary"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Hours"
          name={["hours"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );
};

import { InfoCircleOutlined } from "@ant-design/icons";
import { Create, SaveButtonProps, useSelect } from "@refinedev/antd";
import { BaseKey, IResourceComponentsProps } from "@refinedev/core";
import {
  Checkbox,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";
import { LKR_CURRENCY } from "helpers/constants";
import React from "react";

type PayslipItemCreateProps = IResourceComponentsProps & {
  payslipId: BaseKey;
  formProps: FormProps;
  saveButtonProps: SaveButtonProps;
};

export const PayslipItemCreate: React.FC<PayslipItemCreateProps> = ({
  payslipId,
  formProps,
  saveButtonProps,
}) => {
  const { selectProps: payslipSelectProps } = useSelect({
    resource: "payslips",
  });

  const { selectProps: worklogSelectProps } = useSelect({
    resource: "worklogs",
  });

  const [isWorklog, setIsWorklog] = React.useState(false);

  formProps.form?.setFieldValue("payslipId", payslipId);

  return (
    <Create saveButtonProps={saveButtonProps} breadcrumb={false} goBack={false}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Payslip ID"
          name={"payslipId"}
          rules={[]}
          initialValue={payslipId}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rate"
          name={["rate"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            type="number"
            prefix={LKR_CURRENCY}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          label="Qty"
          name={["qty"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber type="number" />
        </Form.Item>
        <Typography.Text mark>
          <span>
            <InfoCircleOutlined />
            {" You cannot add worklog payslip-items yet"}
          </span>
        </Typography.Text>
        <Form.Item
          valuePropName="checked"
          name={["isWorklog"]}
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }
          initialValue={false}
        >
          <Checkbox
            onChange={(value) => {
              setIsWorklog(value.target.checked);
            }}
            disabled
          >
            Is Worklog
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Worklog"
          hidden={!isWorklog}
          name={"worklogId"}
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }
        >
          <Select {...worklogSelectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};

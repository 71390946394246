import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  useDrawerForm,
  useTable,
} from "@refinedev/antd";
import { BaseKey, BaseRecord, HttpError, useDelete } from "@refinedev/core";
import { Drawer, Space, Table, Tag } from "antd";
import { TPayslipItem } from "pages/payslips/types";
import React from "react";
import { PayslipItemCreate } from "./CreatePayslipItem";
import { PayslipItemEdit } from "./EditPayslipItem";

export const PayslipItemsList: React.FC<{ payslipId: BaseKey }> = ({
  payslipId,
}) => {
  const { mutate: mutateDelete } = useDelete();

  const { tableProps, tableQueryResult } = useTable({
    resource: "payslip-items",
    filters: {
      permanent: [
        {
          field: "payslipId",
          operator: "eq",
          value: payslipId,
        },
      ],
    },
  });

  const onDeleteClick = (recordId: BaseKey) =>
    mutateDelete({
      resource: "payslip-items",
      id: recordId,
      // mutationMode: "undoable",
    });

  const {
    formProps: createFormProps,
    drawerProps: createDrawerProps,
    show: showCreateForm,
    saveButtonProps: createSaveButtonProps,
  } = useDrawerForm<TPayslipItem, HttpError, TPayslipItem>({
    action: "create",
    resource: "payslip-items",
    redirect: false,
  });

  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: showEditForm,
    saveButtonProps: editSaveButtonProps,
  } = useDrawerForm<TPayslipItem, HttpError, TPayslipItem>({
    action: "edit",
    resource: "payslip-items",
    redirect: false,
  });

  return (
    <List title={"Payslip Items"} breadcrumb={false} canCreate={false}>
      <Table {...tableProps}>
        <Table.Column dataIndex={"id"} title={"ID"} />
        <Table.Column dataIndex={"payslipId"} title={"Payslip ID"} />
        <Table.Column dataIndex={"description"} title={"Description"} />
        <Table.Column dataIndex={"qty"} title={"Qty"} />
        <Table.Column dataIndex={"rate"} title={"Rate (LKR)"} />
        <Table.Column
          dataIndex={"isWorklog"}
          title={"Worklog"}
          render={(value: boolean) =>
            value ? <Tag color="cyan">Worklog</Tag> : <>Non-Worklog</>
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => showEditForm(record.id)}
              />
              <DeleteButton
                hideText
                size="small"
                resource="payslip-items"
                recordItemId={record.id}
                mutationMode="undoable"
              />
            </Space>
          )}
        />
      </Table>
      <CreateButton onClick={() => showCreateForm()}>Create Item</CreateButton>
      <Drawer {...createDrawerProps}>
        <PayslipItemCreate
          payslipId={payslipId}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        />
      </Drawer>
      <Drawer {...editDrawerProps}>
        <PayslipItemEdit
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
        />
      </Drawer>
    </List>
  );
};

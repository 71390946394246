import { AuthPage, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";

export const Register = () => {
  const { formProps } = useForm();
  return (
    <AuthPage
      type="register"
      title="Invoyze V3 Admin"
      formProps={{
        initialValues: {
          email: "admin@mail.com",
          password: "Abc@1234",
          firstName: "XYZ",
          lastName: "ABC",
        },
      }}
    />
  );
};

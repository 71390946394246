import { TitleProps } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6/legacy";
import { ColorModeContext } from "contexts/color-mode";
import React, { useContext } from "react";

const { Link } = routerProvider;

const getLogo = (mode: string, collapsed: boolean) => {
  let fileName = "logo";
  if (mode === "dark") {
    fileName += "-dark";
  } else {
    fileName += "-light";
  }
  if (collapsed) {
    fileName += "-collapsed";
  }
  return fileName + ".png";
};

const ImageLogo: React.FC<{ mode?: string; collapsed?: boolean }> = ({
  mode = "light",
  collapsed = false,
}) => {
  let style: React.CSSProperties = {
    width: "200px",
    padding: "12px 24px",
  };

  if (collapsed) {
    style = {
      margin: "0 auto",
      padding: "12px 0",
      maxHeight: "65.5px",
    };
  }

  // const src = "https://zotech.xyz/static/zot-logo-f1265fe86c5b0b97d8ee985d1b00fb48.jpg";

  return collapsed ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={getLogo(mode, collapsed)}
        alt="Invoyze-v2"
        style={{ ...style }}
      />
    </div>
  ) : (
    <img src={getLogo(mode, collapsed)} alt="Invoyze-v2" style={{ ...style }} />
  );
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { mode } = useContext(ColorModeContext);

  return (
    <Link to="/">
      <ImageLogo mode={mode} collapsed={collapsed} />
    </Link>
  );
};

import {
  DateField,
  NumberField,
  Show,
  TextField
} from "@refinedev/antd";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { Col, Row, Typography } from "antd";
import { LKR_CURRENCY } from "helpers/constants";
import React from "react";
import { IProjectExpense } from "./types";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IProjectExpense>();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: projectData, isLoading: projectIsLoading } = useOne({
    resource: "projects",
    id: record?.projectId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Row>
        <Col span={12}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Project</Title>
          {projectIsLoading ? <>Loading...</> : <>{projectData?.data?.name}</>}
          <Title level={5}>Description</Title>
          <TextField value={record?.description} />
          <Title level={5}>Rate</Title>
          <TextField value={`${LKR_CURRENCY} ${record?.rate ?? "<?>"}`} />
          <Title level={5}>Quantity</Title>
          <TextField value={`${record?.qty ?? "<?>"}`} />
          <Title level={5}>Amount</Title>
          <TextField value={`${LKR_CURRENCY} ${(record?.qty ?? 0) * (record?.rate ?? 0)}`} />
          <Title level={5}>Year</Title>
          <TextField value={record?.year ?? ""} />
          <Title level={5}>Month</Title>
          <TextField value={record?.month ?? ""} />
        </Col>
        <Col span={2}></Col>
        <Col span={10}>
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
    </Show>
  );
};

import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable
} from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useMany,
} from "@refinedev/core";
import { Space, Table } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { MONTHS } from "helpers/constants";
import React from "react";
import { IProject } from "./types";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: projectData, isLoading: projectIsLoading } = useMany<IProject>({
    resource: "projects",
    ids: tableProps?.dataSource?.map((item) => item?.project.id) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={["project", "id"]}
          title="Project"
          render={(value: BaseKey) => {
            if (projectIsLoading) return <>....</>;
            let proj = projectData?.data.find((i) => i.id === value);
            return (
              <ResourceNavButton
                buttonType="link"
                resource="projects"
                page="show"
                resourceId={value}
                label={`${proj?.name}`}
              />
            );
          }}
        />
        <Table.Column dataIndex="description" title="Description" />
        {/* <Table.Column dataIndex="rate" title="Rate (LKR)" />
        <Table.Column dataIndex="qty" title="Qty" /> */}
        <Table.Column dataIndex="amount" title="Amount (LKR)"
          render={(_, record: BaseRecord) => (
            `${(record?.qty ?? 0) * (record?.rate ?? 0)}`
          )} />
        <Table.Column dataIndex="month" title="Month"
          render={(_, record: BaseRecord) => {
            if (record.month && record.year) {
              return <>{`${MONTHS[record.month - 1]}-${record.year}`}</>;
            }
            return <>...</>;
          }}
        />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useMany,
} from "@refinedev/core";
import { Space, Table } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { MONTHS } from "helpers/constants";
import { IEmployee } from "pages/employees/types";
import { IProject } from "pages/projects/types";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: employeeData, isLoading: isEmpDataLoading } =
    useMany<IEmployee>({
      resource: "tasks/employees",
      ids:
        tableProps?.dataSource?.map(
          (item) => item?.worklog?.projectEngagement?.employeeId
        ) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { data: projectData, isLoading: isProjDataLoading } = useMany<IProject>(
    {
      resource: "projects",
      ids:
        tableProps?.dataSource?.map(
          (item) => item?.worklog?.projectEngagement?.projectId
        ) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    }
  );

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={["worklog", "projectEngagement", "projectId"]}
          title="Project"
          render={(value: BaseKey) => {
            if (isProjDataLoading) return <>....</>;
            let proj = projectData?.data.find((i) => i.id === value);
            return (
              <ResourceNavButton
                buttonType="link"
                resource="projects"
                page="show"
                resourceId={value}
                label={`${proj?.name}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex={["worklog", "projectEngagement", "employeeId"]}
          title="Employee"
          render={(value: BaseKey) => {
            if (isEmpDataLoading) return <>....</>;
            let employeeUser = employeeData?.data.find(
              (i) => i.id === value
            )?.user;
            return (
              <ResourceNavButton
                buttonType="link"
                resource="employees"
                page="show"
                resourceId={value}
                label={`${employeeUser?.firstName} ${employeeUser?.lastName}`}
              />
            );
          }}
        />
        <Table.Column dataIndex={["summary"]} title="Summary" />
        <Table.Column
          dataIndex={["worklog"]}
          title="Month"
          render={(worklog: any) => {
            if (worklog.month && worklog.year) {
              return <>{`${MONTHS[worklog.month - 1]}-${worklog.year}`}</>;
            }
            return <>...</>;
          }}
        />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

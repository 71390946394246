import { BaseKey } from "@refinedev/core";
import { Button } from "antd";
import { ButtonType } from "antd/es/button";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface ResourceNavButtonProps {
  resource: string;
  page?: "list" | "create" | "edit" | "show";
  buttonType?: ButtonType;
  resourceId?: BaseKey;
  label?: string;
  disabled?: boolean;
  [key: string]: any;
}
const ResourceNavButton: React.FC<ResourceNavButtonProps> = ({
  resourceId,
  label,
  resource,
  page = "list",
  disabled,
  buttonType,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    let path = `/${resource}`;
    if (page == "create") {
      path += `/${page}`;
    } else if ((page == "edit" || page == "show") && resourceId) {
      path += `/${page}/${resourceId}`;
    }
    navigate(path);
  }, [resource, page, resourceId, navigate]);

  return (
    // <Button type={buttonType || "primary"} onClick={handleOnClick} {...rest}>
    //   {label || "Button"}
    // </Button>
    <Button type={buttonType || "primary"} onClick={handleOnClick}>
      {label || "Button"}
    </Button>
  );
};

export default ResourceNavButton;

import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { CL_PREFIX, TITLES } from "helpers/constants";
import React from "react";

export const EditPage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const clientsData = queryResult?.data?.data;

  const titles: { value: string; label: string }[] = TITLES.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={10}>{<BasicFormItems />}</Col>
          <Col span={2}>
            <Divider type="vertical" />
          </Col>
          <Col span={12}>
            {
              <>
                <AddressFormItems />
                <BankAccountFormItems />
              </>
            }
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

const BasicFormItems = () => {
  const titles: { value: string; label: string }[] = TITLES.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  return (
    <>
      <Divider orientation="left">Basic</Divider>
      <Form.Item
        label="Id"
        name={["id"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label="Emp No"
        name={["clientCode"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber addonBefore={CL_PREFIX} />
      </Form.Item>
      <Form.Item
        label="First Name"
        name={["user", "firstName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name={["user", "lastName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name={["user", "userProfile", "email"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Phone"
        name={["user", "userProfile", "phone"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
    </>
  );
};

const AddressFormItems = () => {
  return (
    <>
      <Divider orientation="left">Address</Divider>
      <Form.Item
        label="Address Line 1"
        name={["user", "userProfile", "addressLine_1"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="Address Line 2"
        name={["user", "userProfile", "addressLine_2"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="City"
        name={["user", "userProfile", "city"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="Country"
        name={["user", "userProfile", "country"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
    </>
  );
};

const BankAccountFormItems = () => {
  return (
    <>
      <Divider orientation="left">Bank Account</Divider>
      <Form.Item
        label="Bank Name"
        name={["user", "userProfile", "bankName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="Account No"
        name={["user", "userProfile", "bankAccNo"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="Account Holder Name"
        name={["user", "userProfile", "bankAccName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
      <Form.Item
        label="Bank Branch"
        name={["user", "userProfile", "bankAccBranch"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input autoComplete="" />
      </Form.Item>
    </>
  );
};

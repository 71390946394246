import { Create, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Form, Input, Select } from "antd";
import { TITLES } from "helpers/constants";
import React from "react";
import { IProject } from "./types";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    optionLabel: "user.username",
  });

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  const titles: { value: string; label: string }[] = TITLES.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Employee"
          name={"employeeId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={employeeSelectProps.options?.map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Project"
          name={"projectId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...projectSelectProps} />
        </Form.Item>
        <Form.Item
          label="Role Title"
          name={["roleTitle"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={titles} />
        </Form.Item>
        <Form.Item
          label="Tech Lead"
          name={"techLeadId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={employeeSelectProps.options?.map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Hourly Rate"
          name={["hourlyRate"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};

import {
  Col, Form,
  Input,
  Row
} from "antd";
import React from "react";

export const CompanyDataFormItems = () => (
  <Row>
    <Col span={8}>
      <Form.Item
        label="Company Name"
        name={["companyName"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company City"
        name={["companyCity"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company Country"
        name={["companyCountry"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={2}></Col>
    <Col span={14}>
      <Form.Item
        label="Company Address 1"
        name={["companyAddress_1"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company Address 2"
        name={["companyAddress_2"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Col>
  </Row>
);

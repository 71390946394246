import { EditButton, List, ShowButton, useTable } from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useMany,
} from "@refinedev/core";
import { Space, Table } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { MONTHS } from "helpers/constants";
import { IEmployee } from "pages/employees/types";
import { IProject } from "pages/projects/types";
import React from "react";
import { StatusTag } from "components/StatusTag";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: employeesData, isLoading: employeesLoading } =
    useMany<IEmployee>({
      resource: "employees",
      ids: [].concat(
        ...(tableProps?.dataSource?.map(
          (item) => item?.projectEngagement?.employeeId
        ) ?? [])
      ),
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { data: projectData, isLoading: isProjDataLoading } = useMany<IProject>(
    {
      resource: "projects",
      ids:
        tableProps?.dataSource?.map(
          (item) => item?.worklog?.projectEngagement?.projectId
        ) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    }
  );

  return (
    <List canCreate={false}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="month"
          title="Month"
          render={(value: any) => <>{MONTHS[value - 1]}</>}
        />
        <Table.Column dataIndex="year" title="Year" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value: any) => <StatusTag status={value} />}
        />
        <Table.Column
          dataIndex={["projectEngagement", "projectId"]}
          title="Project"
          render={(value: BaseKey) => {
            if (isProjDataLoading) return <>....</>;
            let proj = projectData?.data.find((i) => i.id === value);
            return (
              <ResourceNavButton
                buttonType="link"
                resource="projects"
                page="show"
                resourceId={value}
                label={`${proj?.name}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex={["projectEngagement", "employeeId"]}
          title="Employee"
          render={(value: BaseKey) => {
            if (employeesLoading) return <>....</>;
            let employeeUser = employeesData?.data.find(
              (i) => i.id === value
            )?.user;
            return (
              <ResourceNavButton
                buttonType="link"
                resource="employees"
                page="show"
                resourceId={value}
                label={`${employeeUser?.firstName} ${employeeUser?.lastName}`}
              />
            );
          }}
        />
        <Table.Column dataIndex="workedHours" title="Worked Hours" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

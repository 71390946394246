import { SearchOutlined } from "@ant-design/icons";
import { Create, useSelect, useStepsForm, useTable } from "@refinedev/antd";
import {
  CrudFilters,
  IResourceComponentsProps,
  useCreate,
  useMany,
} from "@refinedev/core";
import { Button, Form, FormProps, Input, Select, Steps, Table } from "antd";
import { IEmployee } from "pages/employees/types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { MONTHS, getYears } from "helpers/constants";
import ComfirmPayslipMetadata from "./components/CreatePage/ComfirmPayslipMetadata";
import CreatePayslipButton from "./components/CreatePage/CreatePayslipButton";
import WorklogList from "./components/CreatePage/WorklogList";
import { TCreatePayslipFunction, TPayslip } from "./types";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { mutate: createPayslipMutate } = useCreate();
  const navigate = useNavigate();

  const { current, gotoStep, stepsProps } = useStepsForm<TPayslip>();

  const { tableProps, searchFormProps } = useTable({
    resource: "worklogs",
    sorters: {
      permanent: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { q, employee, year, month } = params;

      filters.push(
        {
          field: "q",
          operator: "eq",
          value: q,
        },
        {
          field: "employee.id",
          operator: "eq",
          value: employee,
        },
        {
          field: "year",
          operator: "eq",
          value: year,
        },
        {
          field: "month",
          operator: "eq",
          value: month,
        }
      );

      return filters;
    },
  });

  const { data: employeesData, isLoading: isEmployeesLoading } =
    useMany<IEmployee>({
      resource: "employees",
      ids: [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const searchFormData = searchFormProps.form?.getFieldsValue();

  const handleCreatePayslipButtonClick: TCreatePayslipFunction = (
    year: number,
    month: number,
    employeeId: number,
    worklogs: number[]
  ) => {
    createPayslipMutate &&
      createPayslipMutate(
        {
          resource: "payslips",
          values: {
            year,
            month,
            employeeId,
            worklogs,
          },
        },
        {
          onSuccess: () => {
            setSelectedRowKeys([]);
            navigate("/payslips");
          },
        }
      );
  };

  const formList = [
    <>
      <Filter
        formProps={searchFormProps}
        isEmployeesLoading={isEmployeesLoading}
        employeeSelectData={employeesData?.data}
      />
      <WorklogList tableProps={tableProps} rowSelection={rowSelection} />
    </>,
    <>
      <ComfirmPayslipMetadata
        employee={employeesData?.data.find(
          (e) => e.id == searchFormData.employee
        )}
        selectedWL={selectedRowKeys.map(Number)}
        month={searchFormData.month}
        year={searchFormData.year}
      />
    </>,
  ];

  const footerButtons = (
    <>
      {current > 0 && (
        <Button
          onClick={() => {
            gotoStep(current - 1);
          }}
        >
          Previous
        </Button>
      )}
      {current < formList.length - 1 && (
        <Button
          onClick={() => {
            gotoStep(current + 1);
          }}
        >
          Next
        </Button>
      )}
      {/* {current === formList.length - 1 && <SaveButton {...saveButtonProps} />} */}
      {current === formList.length - 1 && (
        <CreatePayslipButton
          employeeId={
            employeesData?.data.find((e) => e.id == searchFormData.employee)?.id
          }
          selectedWL={selectedRowKeys.map(Number)}
          month={searchFormData.month}
          year={searchFormData.year}
          onClick={handleCreatePayslipButtonClick}
        />
      )}
    </>
  );

  return (
    <>
      <Create footerButtons={footerButtons}>
        <Steps
          {...stepsProps}
          items={[{ title: "Select Worklogs" }, { title: "Create Payslip" }]}
        />
        {formList[current]}
      </Create>
    </>
  );
};

const Filter: React.FC<{
  formProps: FormProps;
  employeeSelectData?: IEmployee[];
  isEmployeesLoading?: boolean;
}> = ({ formProps, employeeSelectData, isEmployeesLoading }) => {
  const { selectProps: employeeSelectProps } = useSelect({
    resource: "employees",
    optionLabel: "user.username",
    optionValue: "id",
  });

  const employeeOptions = employeeSelectData?.map((employee) => ({
    label: `${employee.user?.firstName} ${employee.user?.lastName}`,
    value: employee.id,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Title, Content, etc."
          prefix={<SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Year" name="year" initialValue={2023}>
        <Select options={years} />
      </Form.Item>
      <Form.Item label="Month" name="month" initialValue={5}>
        <Select options={months} />
      </Form.Item>
      <Form.Item label="Employee" name="employee">
        <Select
          loading={isEmployeesLoading}
          options={employeeOptions}
          allowClear
          placeholder="Search Employee"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};

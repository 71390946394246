import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Form, Input, InputNumber, Select } from "antd";
import Password from "antd/es/input/Password";
import { CL_PREFIX, TITLES } from "helpers/constants";
import React from "react";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const titles: { value: string; label: string }[] = TITLES.map((t) => ({
    label: t.name,
    value: t.id,
  }));

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Client No"
          name={["clientCode"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber addonBefore={CL_PREFIX} />
        </Form.Item>
        <Form.Item
          label="First Name"
          name={["user", "firstName"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={["user", "lastName"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={["user", "userProfile", "email"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name={["user", "userProfile", "phone"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input autoComplete="" />
        </Form.Item>
        <Form.Item
          label="Password"
          name={["user", "password"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Password />
        </Form.Item>
      </Form>
    </Create>
  );
};

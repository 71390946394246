import { Card, Space, Typography } from "antd";
import { MONTHS } from "helpers/constants";
import { IEmployee } from "pages/employees/types";
import React from "react";

const ComfirmPayslipMetadata: React.FC<{
  year?: number;
  month?: number;
  employee?: IEmployee;
  selectedWL: number[];
}> = ({ year, month, employee, selectedWL }) => {
  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card>
        <Typography.Text>
          {selectedWL.length > 0
            ? `Selected Worklog IDs: [${selectedWL}] (${selectedWL.length} items)`
            : ""}
        </Typography.Text>
      </Card>
      <Card>
        <Typography.Text>
          {employee
            ? `Employee: ${employee.user?.firstName} ${employee.user?.lastName}`
            : ""}
        </Typography.Text>
      </Card>
      <Card>
        <Typography.Text>{year ? `Year: ${year}` : ""}</Typography.Text>
      </Card>
      <Card>
        <Typography.Text>
          {month ? `Month: ${MONTHS[month - 1]}` : ""}
        </Typography.Text>
      </Card>
    </Space>
  );
};

export default ComfirmPayslipMetadata;

import { TPayslipRecord } from "./types";

export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const constuctPayslipData = (record: TPayslipRecord) => {
  const itemsInPayslip: { description: string; qty: number; rate: number }[] =
    [];

  if (record.payslipItems) {
    itemsInPayslip.push(...record.payslipItems);
  }

  if (record.nonWorklogPayslipItems) {
    itemsInPayslip.push(...record.nonWorklogPayslipItems);
  }

  const data = {
    title: "PAYSLIP",
    companyName: record?.companyName,
    companyAddress: record?.companyAddress_1,
    companyAddress2: record?.companyAddress_2,
    companyCountry: record?.companyCountry,
    name: record?.companyName,
    billTo: "Pay To:",
    clientName: `Name: ${record?.employeeName}`,
    clientAddress: `Bank and Branch: ${record?.employeeBankName}`,
    clientAddress2: `Account Number: ${record?.employeeBankAccNo}`,
    clientCountry: `${record?.employeeCountry}`,
    invoiceTitleLabel: `# ${record?.id.toString()}`,
    invoiceTitle: `${MONTHS[record?.month - 1]}`,
    invoiceDateLabel: "Payslip Date",
    invoiceDate: record?.salaryDate,
    invoiceDueDateLabel: "Transaction Date",
    invoiceDueDate: record?.salaryDate,
    productLineDescription: "Description",
    productLineQuantity: "Hrs",
    productLineQuantityRate: "Rate",
    productLineQuantityAmount: "Amount",
    productLines: itemsInPayslip.map((e) => ({
      description: e.description,
      quantity: e.qty.toString(),
      rate: e.rate.toString(),
    })),
    subTotalLabel: "Sub Total",
    taxLabel: "(Tax)",
    saleTax: record?.tax ?? "0.00",
    totalLabel: "TOTAL",
    currency: "LKR",
    notesLabel: record?.notes_1Title,
    notes: record?.notes_1Description,
    termLabel: record?.notes_2Title,
    term: record?.notes_2Description,
  };

  return data;
};

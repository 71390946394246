import { Button } from "antd";
import React from "react";
import { TCreatePayslipFunction } from "../../types";

const CreatePayslipButton: React.FC<{
  year?: number;
  month?: number;
  employeeId?: number;
  selectedWL: number[];
  onClick?: TCreatePayslipFunction;
}> = ({ year, month, employeeId, selectedWL, onClick }) => {
  if (
    year == undefined ||
    month == undefined ||
    employeeId == undefined ||
    selectedWL.length == 0
  ) {
    return (
      <Button disabled loading>
        Create Payslip
      </Button>
    );
  }

  return (
    <Button
      type="primary"
      onClick={() => onClick && onClick(year, month, employeeId, selectedWL)}
    >
      Create Payslip
    </Button>
  );
};

export default CreatePayslipButton;

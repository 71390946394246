import { EditButton, List, ShowButton } from "@refinedev/antd";
import { BaseKey, BaseRecord, useMany } from "@refinedev/core";
import { Card, Space, Table, TableProps } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import ResourceNavButton from "components/ResourceNavButton";
import { StatusTag } from "components/StatusTag";
import { MONTHS } from "helpers/constants";
import { IEmployee } from "pages/employees/types";
import { IProject } from "pages/projects/types";
import React from "react";

type WorklogListProps = {
  tableProps: TableProps<BaseRecord>;
  rowSelection?: TableRowSelection<BaseRecord>;
};

const WorklogList: React.FC<WorklogListProps> = ({
  tableProps,
  rowSelection,
}) => {
  const { data: employeesData, isLoading: isEmployeesLoading } =
    useMany<IEmployee>({
      resource: "employees",
      ids: [].concat(
        ...(tableProps?.dataSource?.map(
          (item) => item?.projectEngagement?.employeeId
        ) ?? [])
      ),
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { data: projectData, isLoading: isProjDataLoading } = useMany<IProject>(
    {
      resource: "projects",
      ids:
        tableProps?.dataSource?.map(
          (item) => item?.worklog?.projectEngagement?.projectId
        ) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    }
  );

  return (
    <List canCreate={false} title="Select Worklogs" breadcrumb={false}>
      <Card>
        <Table {...tableProps} rowKey="id" rowSelection={rowSelection}>
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="month"
            title="Month"
            render={(value: any) => <>{MONTHS[value - 1]}</>}
          />
          <Table.Column dataIndex="year" title="Year" />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value: any) => <StatusTag status={value} />}
          />
          <Table.Column
            dataIndex={["projectEngagement", "projectId"]}
            title="Project"
            render={(value: BaseKey) => {
              if (isProjDataLoading) return <>....</>;
              let proj = projectData?.data.find((i) => i.id === value);
              return (
                <ResourceNavButton
                  buttonType="link"
                  resource="projects"
                  page="show"
                  resourceId={value}
                  label={`${proj?.name}`}
                />
              );
            }}
          />
          <Table.Column
            dataIndex={["projectEngagement", "employeeId"]}
            title="Employee"
            render={(value: BaseKey) => {
              if (isEmployeesLoading) return <>....</>;
              let employeeUser = employeesData?.data.find(
                (i) => i.id === value
              )?.user;
              return (
                <ResourceNavButton
                  buttonType="link"
                  resource="employees"
                  page="show"
                  resourceId={value}
                  label={`${employeeUser?.firstName} ${employeeUser?.lastName}`}
                />
              );
            }}
          />
          <Table.Column dataIndex="workedHours" title="Worked Hours" />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};

export default WorklogList;

import { AuthBindings } from "@refinedev/core";

// In-app
import {
  API_URL,
  RANDOM_AVATAR_LINK,
  TOKEN_KEY,
  USER_KEY,
} from "helpers/constants";
import axiosInstance from "./helpers/axiosHelper";

// export const axiosInstance = axios.create();

// export interface AuthSuccessResponse {
//   access_token: string;
//   user: IAuthenticatedUser;
// }

interface IAuthenticatedUser {
  id: number;
  email: string;
  roles: string[];
  firstName: string;
  lastName: string;
}

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    if (!email || !password) {
      // username and password are required
      return {
        success: false,
        error: {
          name: "LoginError",
          message: "Invalid username or password",
        },
      };
    }

    const { status, data } = await axiosInstance.post(`${API_URL}/auth/login`, {
      username: email,
      password: password,
    });

    if (status !== 201 || !data || !data.access_token || !data.user) {
      return {
        success: false,
        error: {
          name: "LoginError",
          message: "Invalid username or password",
        },
      };
    }

    const authUser: IAuthenticatedUser = data.user;

    localStorage.setItem(TOKEN_KEY, data.access_token);
    localStorage.setItem(USER_KEY, JSON.stringify(authUser));

    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${data.access_token}`,
    };

    return {
      success: true,
      redirectTo: "/",
    };
  },
  register: async ({ email, password, firstName, lastName }) => {
    firstName = "ABC";
    lastName = "XYZ";

    if (!email || !password || !firstName || !lastName) {
      // username and password are required
      return {
        success: false,
        error: {
          name: "RegisterError",
          message: "Invalid attributes",
        },
      };
    }

    const { status, data } = await axiosInstance.post(
      `${API_URL}/auth/register`,
      {
        email,
        password,
        firstName,
        lastName,
      }
    );

    if (status !== 201 || !data || !data.access_token || !data.user) {
      return {
        success: false,
        error: {
          name: "RegisterError",
          message: "Invalid attributes",
        },
      };
    }

    const authUser: IAuthenticatedUser = data.user;

    localStorage.setItem(TOKEN_KEY, data.access_token);
    localStorage.setItem(USER_KEY, JSON.stringify(authUser));

    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${data.access_token}`,
    };

    return {
      success: true,
      redirectTo: "/",
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    const authUser = localStorage.getItem(USER_KEY);
    if (token && authUser) {
      return { ...JSON.parse(authUser), avatar: RANDOM_AVATAR_LINK };
    }
    return null;
  },
  onError: async (error) => {
    // Doc: https://refine.dev/docs/api-reference/core/hooks/authentication/useOnError/

    // Handle 401 Unauthorized error
    if (error.statusCode === 401) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }

    return {};
  },
};

import { Create, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { IProject } from "./types";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Project"
              name={"projectId"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...projectSelectProps} />
            </Form.Item>
            <Form.Item
              label="Description"
              name={["description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Amount"
              name={["amount"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={10}>
            <Form.Item
              label="Year"
              name={["year"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Month"
              name={["month"]}
              rules={[
                {
                  required: true,
                  max: 12,
                  min: 1,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Has Paid"
              valuePropName="checked"
              name={["hasPaid"]}
              rules={[{}]}
            >
              <Checkbox disabled>Has Paid</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

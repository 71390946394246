import { Create, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps, useMany, useTable } from "@refinedev/core";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { LKR_CURRENCY, PROJECT_TYPES } from "helpers/constants";
import React from "react";
import { IClient, IUser } from "./types";
import ResourceNavButton from "components/ResourceNavButton";
import TextArea from "antd/es/input/TextArea";
import { useMatch } from "react-router-dom";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: clientSelectProps } = useSelect({
    resource: "clients",
    optionLabel: "user.username",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Project Code"
              name={["projectCode"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Name"
              name={["name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name={["description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              label="Project Type"
              name={["projectType"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={PROJECT_TYPES.map((type) => ({
                  label: type,
                  value: type,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={10} offset={2}>
            <Form.Item
              label="Client"
              name={"clientId"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={clientSelectProps.options?.map(({ label, value }) => ({
                  label: label,
                  value: value,
                }))}
              />
            </Form.Item>
            <ResourceNavButton
              resource="clients"
              buttonType="default"
              label="+ New CLient"
              page="create"
              size="small"
            />
            <Divider />
            <Form.Item
              label="Billing Hourly Rate"
              name={["billingHourlyRate"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber addonBefore={LKR_CURRENCY} />
            </Form.Item>
            <Form.Item
              label="Target Dev Hours"
              name={["targetDevHours"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber addonBefore="Hours" />
            </Form.Item>
            <Form.Item
              label="Duration Months"
              name={["durationMonths"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber addonBefore="Months" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

import {
  DateField,
  EditButton,
  EmailField,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useMany,
  useNotification,
} from "@refinedev/core";
import { Space, Table } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PayslipButton from "components/PayslipButton";
import ResourceNavButton from "components/ResourceNavButton";
import { MONTHS } from "helpers/constants";

import { TPayslip } from "./types";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { open: notify } = useNotification();
  const apiUrl = useApiUrl();
  const navigate = useNavigate();

  const { data: hasCompanyResponse, isLoading: isHasCompanyLoading } =
    useCustom({
      url: `${apiUrl}/company/has`,
      method: "get",
      config: {
        // TODO: Remove the following after learning it.
        // headers: {
        //     "x-custom-header": "foo-bar",
        // },
        // query: {
        //     title: "Foo bar",
        // },
      },
    });

  useEffect(() => {
    if (!isHasCompanyLoading && !hasCompanyResponse?.data) {
      // Notify the user that company details are not added.
      notify?.({
        message: "Please add company details",
        description: "Empty Company!",
        type: "error",
      });
    }
  }, [hasCompanyResponse?.data, notify]);

  if (!isHasCompanyLoading && !hasCompanyResponse?.data) {
    // Navigating to company-settings page since company details are not added.
    navigate("/company-settings");
  }

  const { tableProps } = useTable<TPayslip>({
    resource: "payslips",
  });

  const { data: employeeData, isLoading: isEmpDataLoading } = useMany({
    resource: "employees",
    ids:
      tableProps?.dataSource?.map((item) => item?.employeeId as BaseKey) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        loading={isHasCompanyLoading && isEmpDataLoading}
      >
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="month"
          title="Month"
          render={(value: any) => MONTHS[value - 1]}
        />
        <Table.Column dataIndex="year" title="Year" />
        <Table.Column
          dataIndex={["employeeId"]}
          title="Employee"
          render={(value: BaseKey) => {
            if (isEmpDataLoading) return <>....</>;
            let employeeUser = employeeData?.data.find(
              (i) => i.id === value
            )?.user;
            return (
              <ResourceNavButton
                buttonType="link"
                resource="employees"
                page="show"
                resourceId={value}
                label={`${employeeUser?.firstName} ${employeeUser?.lastName}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex={["sendToEmail"]}
          title="Send To Email"
          render={(value: any) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/* TODO: Show button is disabled. remove `disabled onClick={() => {}}` once the issue is fixed 
              GH issue: https://github.com/team-zeroone/invoyze/issues/98 */}
              <ShowButton disabled onClick={() => {}} hideText size="small" recordItemId={record.id} />
              <PayslipButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

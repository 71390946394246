import {
  DateField,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseRecord,
  IResourceComponentsProps,
  useDeleteMany,
} from "@refinedev/core";
import { Button, Space, Table } from "antd";
import React from "react";
import { IEmployee } from "./types";
import { DeleteOutlined } from "@ant-design/icons";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<IEmployee>();

  const deleteSelectedItems = () => {
    mutate(
      {
        resource: "employees",
        ids: selectedRowKeys.map(Number),
        mutationMode: "undoable",
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([]);
        },
      }
    );
  };

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <List
      headerProps={{
        subTitle: (
          <>
            <Button
              danger
              hidden={!hasSelected}
              type="primary"
              color="red"
              onClick={deleteSelectedItems}
              disabled={!hasSelected}
              loading={deleteManyIsLoading}
              icon={<DeleteOutlined />}
            >
              Selected
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span>
          </>
        ),
      }}
    >
      <Table {...tableProps} rowSelection={rowSelection} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="empNo" title="Emp No" />
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column dataIndex={["user", "firstName"]} title="First Name" />
        <Table.Column dataIndex={["user", "lastName"]} title="Last Name" />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

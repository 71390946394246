import { Tag } from "antd";
import React from "react";

export const StatusTag: React.FC<{ status: string; }> = ({ status }) => {
  switch (status) {
    case "Approved":
      return <Tag color="green">Approved</Tag>;
    case "Rejected":
      return <Tag color="red">Rejected</Tag>;
    default:
      return <Tag color="gold">Pending</Tag>;
  }
};

import { Create, useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { MONTHS, getYears } from "helpers/constants";
import React from "react";
import { IProject } from "./types";

export const CreatePage: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "name",
    optionValue: "id",
  });

  const years: { value: number; label: number }[] = getYears().map((year) => ({
    label: year,
    value: year,
  }));

  const months: { value: number; label: string }[] = MONTHS.map(
    (mName, index) => ({
      label: mName,
      value: index + 1,
    })
  );

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Project"
              name={"projectId"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select {...projectSelectProps} />
            </Form.Item>
            <Form.Item
              label="Description"
              name={["description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Rate"
              name={["rate"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Quantity"
              name={["qty"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={10}>
            <Form.Item
              label="Year"
              name={["year"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={years} />
            </Form.Item>
            <Form.Item
              label="Month"
              name={["month"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={months} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};

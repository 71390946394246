import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useMany,
} from "@refinedev/core";
import { Space, Table } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import React from "react";
import { IClient } from "./types";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: clientData, isLoading: clientIsLoading } = useMany<IClient>({
    resource: "clients",
    ids: tableProps?.dataSource?.map((item) => item?.clientId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="projectCode" title="Project Code" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="billingHourlyRate"
          title="Hourly Billing Rate (LKR)"
        />
        <Table.Column dataIndex="durationMonths" title="Duration (Months)" />
        <Table.Column dataIndex="targetDevHours" title="Target Dev Hours" />
        <Table.Column dataIndex="projectType" title="Project Type" />
        <Table.Column
          dataIndex={["clientId"]}
          title="Client"
          render={(value: BaseKey) => {
            if (clientIsLoading) return <>....</>;
            let clientUser = clientData?.data.find((i) => i.id === value)?.user;
            return (
              <ResourceNavButton
                buttonType="link"
                resource="clients"
                page="show"
                resourceId={value}
                label={`${clientUser?.firstName} ${clientUser?.lastName}`}
              />
            );
          }}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                // disabled
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

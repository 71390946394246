import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useMany,
  useOne,
  useShow,
} from "@refinedev/core";
import { Col, Divider, List, Row, Table, Typography } from "antd";
import { MONTHS } from "helpers/constants";
import { ITask } from "pages/tasks/types";
import { StatusTag } from "components/StatusTag";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: employeeData, isLoading: employeeIsLoading } = useOne({
    resource: "employees",
    id: record?.projectEngagement?.employeeId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: statusChangedLeadData, isLoading: statusChangedLeadIsLoading } =
    useOne({
      resource: "employees",
      id: record?.statusChangedById || "",
      queryOptions: {
        enabled: !!record,
      },
    });

  const { data: projectData, isLoading: projectIsLoading } = useOne({
    resource: "projects",
    id: record?.projectEngagement?.projectId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: tasksData, isLoading: tasksIsLoading } = useMany<ITask>({
    resource: "tasks",
    ids: record?.tasks?.map((t: { id: number }) => t?.id) || [],
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading} canDelete={false}>
      <Row>
        <Col span={12}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Employee</Title>
          {employeeIsLoading ? (
            <>Loading...</>
          ) : (
            <>{`${employeeData?.data?.user.firstName} ${employeeData?.data?.user.lastName}`}</>
          )}
          <Title level={5}>Project</Title>
          {projectIsLoading ? <>Loading...</> : <>{projectData?.data?.name}</>}
          <Title level={5}>Month</Title>
          <TextField value={`${MONTHS[record?.month - 1]}-${record?.year}`} />
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <Title level={5}>Status</Title>
          <StatusTag status={record?.status} />
          <br />
          <Title level={5}>Status Changed by</Title>
          {statusChangedLeadIsLoading || !record?.statusChangedById ? (
            <>...</>
          ) : (
            <>{`${statusChangedLeadData?.data?.user.firstName} ${statusChangedLeadData?.data?.user.lastName}`}</>
          )}
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <List header={<Title level={5}>Tasks</Title>}>
          <Table dataSource={tasksData?.data} loading={tasksIsLoading}>
            <Table.Column title="Id" dataIndex="id" />
            <Table.Column title="Summary" dataIndex="summary" />
            <Table.Column title="Description" dataIndex="description" />
            <Table.Column
              title="Created At"
              dataIndex="createdAt"
              render={(value: any) => <DateField value={value} />}
            />
          </Table>
        </List>
      </Row>
    </Show>
  );
};

import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useOne, useShow } from "@refinedev/core";
import { Col, Row, Typography } from "antd";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: employeeData, isLoading: employeeIsLoading } = useOne({
    resource: "employees",
    id: record?.worklog?.projectEngagement?.employeeId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const { data: projectData, isLoading: projectIsLoading } = useOne({
    resource: "projects",
    id: record?.worklog?.projectEngagement?.projectId || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  return (
    <Show isLoading={isLoading} canDelete={false}>
      <Row>
        <Col span={12}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Employee</Title>
          {employeeIsLoading ? (
            <>Loading...</>
          ) : (
            <>{`${employeeData?.data?.user.firstName} ${employeeData?.data?.user.lastName}`}</>
          )}
          <Title level={5}>Project</Title>
          {projectIsLoading ? <>Loading...</> : <>{projectData?.data?.name}</>}
          <Title level={5}>Summary</Title>
          <TextField value={record?.summary} />
          <Title level={5}>Description</Title>
          <TextField value={record?.description} />
          <Title level={5}>Year</Title>
          <TextField value={record?.worklog?.year} />
          <Title level={5}>Month</Title>
          <TextField value={record?.worklog?.month} />
        </Col>
        <Col span={4}></Col>
        <Col span={8}>
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
    </Show>
  );
};

import { InfoCircleOutlined } from "@ant-design/icons";
import { Edit, SaveButtonProps, useSelect } from "@refinedev/antd";
import { BaseKey, IResourceComponentsProps } from "@refinedev/core";
import {
  Checkbox,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";
import { LKR_CURRENCY } from "helpers/constants";
import React, { useEffect } from "react";

type PayslipItemEditProps = IResourceComponentsProps & {
  isEditable?: boolean;
  formProps: FormProps;
  saveButtonProps: SaveButtonProps;
};

export const PayslipItemEdit: React.FC<PayslipItemEditProps> = ({
  isEditable,
  formProps,
  saveButtonProps,
}) => {
  const { selectProps: worklogSelectProps } = useSelect({
    resource: "worklogs",
  });

  const [isWorklog, setIsWorklog] = React.useState(
    formProps.form?.getFieldValue("isWorklog")
  );

  useEffect(() => {
    setIsWorklog(formProps.form?.getFieldValue("isWorklog"));
  }, [formProps.form?.getFieldValue("isWorklog")]);

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      breadcrumb={false}
      goBack={false}
      headerButtons={<></>}
    >
      <Form {...formProps} layout="vertical" disabled={isWorklog}>
        <Typography.Text mark>
          <span>
            <InfoCircleOutlined />
            {" Payslip associated with worklogs cannot be edited"}
          </span>
        </Typography.Text>
        <Form.Item label="ID" name={"id"} rules={[]}>
          <Input />
        </Form.Item>
        <Form.Item label="Payslip ID" name={"payslipId"} rules={[]}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Description"
          name={["description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Rate"
          name={["rate"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            type="number"
            prefix={LKR_CURRENCY}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          label="Qty"
          name={["qty"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber type="number" />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name={["isWorklog"]}
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }
          initialValue={false}
        >
          <Checkbox
            onChange={(value) => {
              setIsWorklog(value.target.checked);
            }}
            disabled
          >
            Is Worklog
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Worklog"
          hidden={!isWorklog}
          name={"worklogId"}
          rules={
            [
              // {
              //   required: true,
              // },
            ]
          }
        >
          <Select {...worklogSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};

import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Col, Divider, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

export const ShowPage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row>
        <Col span={10}>
          <Title level={5}>Id</Title>
          <NumberField value={record?.id ?? ""} />
          <Title level={5}>Emp No</Title>
          <NumberField value={record?.empNo ?? ""} />
          <Title level={5}>Title</Title>
          <TextField value={record?.title} />
          <Title level={5}>First Name</Title>
          <TextField value={record?.user?.firstName} />
          <Title level={5}>Last Name</Title>
          <TextField value={record?.user?.lastName} />
          <Title level={5}>Email</Title>
          <TextField value={record?.user?.username} />
        </Col>
        <Col span={2}>
          <Divider type="vertical" />
        </Col>
        <Col span={12}>
          <Title level={5}>Address Line 1</Title>
          <TextField value={record?.user?.userProfile?.addressLine_1} />
          <Title level={5}>Address Line 2</Title>
          <TextField value={record?.user?.userProfile?.addressLine_2} />
          <Title level={5}>City</Title>
          <TextField value={record?.user?.userProfile?.city} />
          <Title level={5}>Country</Title>
          <TextField value={record?.user?.userProfile?.country} />
          <Divider />
          <Title level={5}>Bank Name</Title>
          <TextField value={record?.user?.userProfile?.bankName} />
          <Title level={5}>Bank Account No</Title>
          <TextField value={record?.user?.userProfile?.bankAccNo} />
          <Title level={5}>Account Holder Name</Title>
          <TextField value={record?.user?.userProfile?.bankAccName} />
          <Title level={5}>bank Branch</Title>
          <TextField value={record?.user?.userProfile?.bankAccBranch} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12}>
          <Title level={5}>Created At</Title>
          <DateField value={record?.createdAt} />
        </Col>
        <Col span={12}>
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>
    </Show>
  );
};

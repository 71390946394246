import {
  DateField,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import {
  BaseKey,
  BaseRecord,
  IResourceComponentsProps,
  useMany,
} from "@refinedev/core";
import { Space, Table } from "antd";
import ResourceNavButton from "components/ResourceNavButton";
import { IEmployee } from "pages/employees/types";
import React from "react";

export const ListPage: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: employeeData, isLoading: employeeIsLoading } =
    useMany<IEmployee>({
      resource: "employees",
      ids: tableProps?.dataSource?.map((item) => item?.employeeId) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { data: projectData, isLoading: projectIsLoading } = useMany({
    resource: "projects",
    ids: tableProps?.dataSource?.map((item) => item?.projectId) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={["employeeId"]}
          title="Employee"
          render={(value) => {
            if (employeeIsLoading) {
              return <>Loading...</>;
            }
            const empUser = employeeData?.data?.find(
              (item) => item.id === value
            )?.user;
            return (
              <ResourceNavButton
                buttonType="default"
                resource="clients"
                page="show"
                resourceId={value}
                label={`${empUser?.firstName} ${empUser?.lastName}`}
              />
            );
          }}
        />
        <Table.Column
          dataIndex={["projectId"]}
          title="Project"
          render={(value: BaseKey) => {
            if (projectIsLoading) return <>....</>;
            let proj = projectData?.data.find((i) => i.id === value);
            return (
              <ResourceNavButton
                buttonType="link"
                resource="projects"
                page="show"
                resourceId={value}
                label={`${proj?.name}`}
              />
            );
          }}
        />

        <Table.Column dataIndex="roleTitle" title="Role Title" />
        <Table.Column dataIndex="hourlyRate" title="Hourly Rate (LKR)" />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
